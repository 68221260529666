import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto",attrs:{"outlined":""}},[_c(VAppBar,[_c('h2',[_vm._v("Expressions")]),_c(VSpacer),_c(VCol,{attrs:{"md":"3"}},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","data-cy":"search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c(VDataTable,{attrs:{"dense":"","headers":_vm.headers,"items":_vm.expressions,"items-per-page":15,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"search":_vm.search,"show-expand":"","data-cy":"expressions-table"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"35px"},attrs:{"colspan":headers.length}},[_c('versionedExpressions',{ref:"versionedExpression",attrs:{"expressionCorrelationId":item.correlationId},on:{"onDeleted":function($event){return _vm.getAll()}}})],1)]}},{key:"item.data-table-expand",fn:function(ref){
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(!isExpanded)?_c('friss-icon',{attrs:{"data-cy":"expand_versions","tabindex":"0","title":"Show versions","name":"chevron-down-alt-lg"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return expand(true)},"click":function($event){return expand(true)}}}):_vm._e(),(isExpanded)?_c('friss-icon',{attrs:{"data-cy":"collapse_versions","tabindex":"0","title":"Hide versions","name":"chevron-up-alt-lg"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return expand(false)},"click":function($event){return expand(false)}}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('tooltip',{attrs:{"message":"Edit"}},[_c('friss-icon',{staticClass:"mr-3",attrs:{"data-cy":"edit","name":"edit"},on:{"click":function($event){return _vm.editItem(item.id)}}})],1),_c('tooltip',{attrs:{"message":"Copy to..."}},[_c('friss-icon',{staticClass:"mr-3",attrs:{"data-cy":"copy","name":"copy","disabled":!_vm.$permissions.canModifyDataSource},on:{"click":function () { return _vm.onCopyExpressionClick(item); }}})],1)]}}],null,true)}),(_vm.$permissions.canModifyDataSource)?_c('div',{staticStyle:{"display":"flex","padding":"20px","justify-content":"end"}},[_c('applyExpressions',{attrs:{"datasourceId":_vm.datasourceId}},[_c(VBtn,{attrs:{"color":"secondary","rounded":"","outlined":"","data-cy":"apply"}},[_vm._v(" Apply ")])],1),_c(VBtn,{staticClass:"ml-2",attrs:{"color":"secondary","rounded":"","outlined":"","data-cy":"add"},on:{"click":function($event){return _vm.addItem()}}},[_vm._v(" Add expression ")])],1):_vm._e(),(_vm.selectedExpressionId !== null)?_c('expression',{attrs:{"expressionId":_vm.selectedExpressionId,"dataSourceId":_vm.datasourceId,"isDraft":true,"schema":_vm.dataSourceSchema},on:{"saved":function($event){return _vm.refreshTables()},"close":function($event){_vm.selectedExpressionId = null}}}):_vm._e(),(_vm.expressionToCopy)?_c('copy-expression',{attrs:{"expression":_vm.expressionToCopy,"currentDataProviderId":_vm.dataproviderId,"currentDataSourceId":_vm.datasourceId},on:{"close":function($event){_vm.expressionToCopy = null},"copied":function($event){_vm.expressionToCopy = null;
      _vm.getAll();}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }