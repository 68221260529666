import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VCardText,[_c('h4',[_vm._v("Bearer token details")])]),_c('div',{staticStyle:{"padding":"16px","margin-bottom":"32px"}},[_c(VRow,[_c(VCol,{attrs:{"md":"4"}},[_c(VSelect,{attrs:{"items":_vm.$lookupService.grantTypes,"label":"Grant Type","data-cy":"granttype","readonly":_vm.readonly},on:{"change":_vm.onGrantTypeChange},model:{value:(_vm.model.grantType),callback:function ($$v) {_vm.$set(_vm.model, "grantType", $$v)},expression:"model.grantType"}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VSelect,{attrs:{"items":_vm.contentTypes,"label":"Content Type","data-cy":"content-type","readonly":_vm.readonly},model:{value:(_vm.model.contentType),callback:function ($$v) {_vm.$set(_vm.model, "contentType", $$v)},expression:"model.contentType"}})],1),_c(VCol,{attrs:{"md":"4"}},[_c('tooltip',{attrs:{"message":"Send credentials using Basic auth scheme"}},[_c(VCheckbox,{attrs:{"label":"Encode credentials to base 64","data-cy":"encode","readonly":_vm.readonly},model:{value:(_vm.model.encodeCredentialsToBase64),callback:function ($$v) {_vm.$set(_vm.model, "encodeCredentialsToBase64", $$v)},expression:"model.encodeCredentialsToBase64"}})],1)],1)],1),(_vm.isPasswordGrantType)?_c('bearer-grant-type-password',{attrs:{"model":_vm.model,"readonly":_vm.readonly}}):_vm._e(),(_vm.isClientCredentialsGrantType)?_c('bearer-grant-type-client-credentials',{attrs:{"model":_vm.model,"readonly":_vm.readonly}}):_vm._e(),_c(VExpansionPanels,[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{attrs:{"data-cy":"additional-fields"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('span',[_vm._v(" Additional fields ")]),(!open)?_c('span',{staticClass:"panel-summary"},[_vm._v(_vm._s(Object.entries(_vm.model.additionalFields).length)+" field(s)")]):_vm._e()])]}}])}),_c(VExpansionPanelContent,[_c('additional-fields-component',{attrs:{"model":_vm.model.additionalFields,"readonly":_vm.readonly},on:{"onChange":function (dict) { return (_vm.model.additionalFields = dict); }}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }