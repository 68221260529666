import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{attrs:{"app":""}},[_c(VToolbarTitle,[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":"/"}},[_c(VIcon,[_vm._v("$frissLogo")])],1)],1),_c(VSpacer),_c(VSpacer),(_vm.isAuthenticated)?_c('div',[_c(VMenu,{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","data-cy":"options"}},'v-btn',attrs,false),on),[_c('friss-icon',{attrs:{"name":"person"}})],1)]}}],null,false,1152413118)},[_c(VList,{attrs:{"dense":""}},[_c(VListItemGroup,{attrs:{"color":"primary"}},[_c(VListItem,{attrs:{"data-cy":"email"}},[_vm._v(_vm._s(_vm.emailAddress))]),_c(VListItem,{attrs:{"data-cy":"logout"}},[_c(VListItemTitle,{on:{"click":function($event){return _vm.signOut()}}},[_vm._v("Log out")])],1),_c(VListItem,{attrs:{"data-cy":"usermanual"}},[_c(VListItemTitle,[_c('a',{staticStyle:{"text-decoration":"none","color":"rgba(0, 0, 0, 0.87)"},attrs:{"href":"https://dev.azure.com/Friss/Friss/_wiki/wikis/Friss.wiki/1109/User-Manual","target":"_blank"}},[_vm._v("User Manual")])])],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }