import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":550},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{staticClass:"mx-auto",attrs:{"outlined":""}},[_c(VAppBar,[_c('h2',[_vm._v("Data provider")])]),_c(VCardText,[_c(VForm,{ref:"form"},[_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"data-cy":"name","disabled":!!_vm.dataProviderId,"label":"Name","rules":_vm.rules.name},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})],1),_c(VCol,[_c(VTextField,{attrs:{"data-cy":"description","label":"Description","required":""},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}})],1)],1),_c(VRow,[_c(VCol,[_c('dataProviderLinks',{attrs:{"links":_vm.model.links}})],1)],1)],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"data-cy":"cancel","color":"secondary","outlined":""},on:{"click":_vm.cancel}},[_vm._v("Cancel")]),(_vm.$permissions.canModifyDataSource)?_c(VBtn,{attrs:{"data-cy":"save","color":"primary"},on:{"click":_vm.checkAndSave}},[_vm._v("Save")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }