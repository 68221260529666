import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardText,[_c('h4',[_vm._v("Visualization")]),_c(VTextField,{staticStyle:{"width":"20%"},attrs:{"data-cy":"tablename","label":"Table Name","rules":_vm.rules.tableName},model:{value:(_vm.select.label),callback:function ($$v) {_vm.$set(_vm.select, "label", $$v)},expression:"select.label"}}),_c(VSimpleTable,{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('td',{attrs:{"width":"60%"}},[_vm._v("Path")]),_c('td',{attrs:{"width":"40%"}},[_vm._v("Label")]),_c('td',[_c('tooltip',{attrs:{"message":"Add header"}},[_c(VIcon,{attrs:{"data-cy":"outputadd"},on:{"click":function($event){return _vm.add()}}},[_vm._v("mdi-plus")])],1)],1)])]),_c('tbody',_vm._l((_vm.select.fields),function(item,i){return _c('tr',{key:i},[_c('td',[(!_vm.schema || _vm.schema.length === 0)?_c(VTextField,{attrs:{"data-cy":"outputfield","rules":_vm.rules.path},model:{value:(item.path),callback:function ($$v) {_vm.$set(item, "path", $$v)},expression:"item.path"}}):_c(VCombobox,{attrs:{"items":_vm.schema.map(function (x) { return x.path; }),"data-cy":"outputfield","item-text":"path","item-value":"path","rules":_vm.rules.path,"clearable":""},model:{value:(item.path),callback:function ($$v) {_vm.$set(item, "path", $$v)},expression:"item.path"}})],1),_c('td',[_c(VTextField,{attrs:{"data-cy":"outputlabel","rules":_vm.rules.label},model:{value:(item.label),callback:function ($$v) {_vm.$set(item, "label", $$v)},expression:"item.label"}})],1),_c('td',[_c('tooltip',{attrs:{"message":"Delete"}},[(_vm.$permissions.canModifyDataSource)?_c('friss-icon',{attrs:{"data-cy":"paramdelete","name":"delete"},on:{"click":function($event){return _vm.remove(i)}}}):_vm._e()],1)],1)])}),0)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }