import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto",attrs:{"outlined":""}},[_c(VAppBar,[_c('h2',[_vm._v("Certificates")]),_c(VSpacer),_c(VCol,{attrs:{"md":"3"}},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","data-cy":"search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c(VDataTable,{attrs:{"dense":"","headers":_vm.headers,"items":_vm.certificates,"items-per-page":15,"search":_vm.search},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('tooltip',{attrs:{"message":"Edit"}},[_c('friss-icon',{staticClass:"mr-3",attrs:{"data-cy":"certificate-edit","name":"edit"},on:{"click":function($event){return _vm.editCertificate(item)}}})],1),(_vm.$permissions.canModifyCertificate)?_c('confirm',{staticClass:"mr-3",attrs:{"title":"Delete certificate","yesText":"Delete","noText":"Cancel","message":'Are you sure you want to delete certificate ' + item.name + '?'},on:{"confirmed":function($event){return _vm.deleteItem(item.id)}}},[_c('tooltip',{attrs:{"message":"Delete"}},[_c('friss-icon',{attrs:{"data-cy":"delete","name":"delete"}})],1)],1):_vm._e()]}}],null,true)}),_c('div',{staticStyle:{"display":"flex","padding":"20px","justify-content":"end"}},[(_vm.$permissions.canModifyCertificate)?_c(VBtn,{attrs:{"data-cy":"certificate-add","color":"secondary","outlined":"","rounded":""},on:{"click":function($event){return _vm.editCertificate(null)}}},[_vm._v(" Add certificate ")]):_vm._e()],1),(_vm.showDialog)?_c('certificateDialog',{attrs:{"model":_vm.certificateModel},on:{"saved":_vm.getAll,"close":function($event){_vm.showDialog = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }