import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c(VRow,[_c(VCol,{attrs:{"md":"6","data-cy":"authentication"}},[_c(VSelect,{attrs:{"color":"secondary","items":_vm.authenticationMethods,"label":"Authentication Method","hint":"How to authenticate, will affect the connection creation UI","persistent-hint":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.authInfo)?_c('tooltip',{attrs:{"message":_vm.authInfo}},[_c(VIcon,[_vm._v("mdi-information")])],1):_vm._e()]},proxy:true}]),model:{value:(_vm.authenticationMethod),callback:function ($$v) {_vm.authenticationMethod=$$v},expression:"authenticationMethod"}})],1),(_vm.isCustomAuth)?_c(VCol,{attrs:{"md":"6","data-cy":"customAuth"}},[_c(VSelect,{attrs:{"items":_vm.$lookupService.customAuthentications,"label":"Custom Authentication"},model:{value:(_vm.model.authenticationMethod),callback:function ($$v) {_vm.$set(_vm.model, "authenticationMethod", $$v)},expression:"model.authenticationMethod"}})],1):_vm._e(),(_vm.isAuthCertificate)?_c(VCol,{attrs:{"md":"6","data-cy":"signMethod"}},[_c(VSelect,{attrs:{"items":_vm.$lookupService.signingMethods,"label":"Signing Method"},model:{value:(_vm.model.signingMethod),callback:function ($$v) {_vm.$set(_vm.model, "signingMethod", $$v)},expression:"model.signingMethod"}})],1):_vm._e()],1),(_vm.showAuthUri)?_c(VRow,_vm._l((_vm.dataSource.configuration.stages),function(dataSourceStage){return _c(VCol,{key:dataSourceStage.stage,attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"data-cy":"tokenuri","label":dataSourceStage.name + ' authentication  url',"hint":'Example: https://identity.' +
          dataSourceStage.name.toLowerCase() +
          '.vendor.com/connect/token',"persistent-hint":"","rules":_vm.rules.url},model:{value:(dataSourceStage.tokenUri),callback:function ($$v) {_vm.$set(dataSourceStage, "tokenUri", $$v)},expression:"dataSourceStage.tokenUri"}})],1)}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }