import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card-with-switch-component',{attrs:{"isOpen":_vm.model.asyncOptions,"title":"Is asynchronous","hint":"Enable asynchronous behavior","disabled":_vm.model.responseType === _vm.ResponseType.Raw,"data-cy":"asyncEnabled"},on:{"change":_vm.onAsyncEnabledChange}},[(_vm.model.asyncOptions)?_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{ref:"timeout",attrs:{"id":"panelAsyncTimeout","data-cy":"timeout","value":_vm._f("secondsTo")(_vm.model.asyncOptions.timeout,'minutes'),"label":"Timeout (minutes)","type":"number","rules":_vm.rules.timeout,"hint":"The amount of time after which the background polling will be stopped","persistent-hint":""},on:{"input":function (value) {
            _vm.model.asyncOptions.timeout = _vm.convert.minutesTo(value, 'seconds');
            _vm.validateRetryTimespans();
          }}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{ref:"interval",attrs:{"id":"panelAsyncInterval","data-cy":"interval","value":_vm._f("secondsTo")(_vm.model.asyncOptions.interval,'minutes'),"type":"number","label":"Interval (minutes)","rules":_vm.rules.interval,"hint":"The time to wait between each background poll","persistent-hint":""},on:{"input":function (value) {
            _vm.model.asyncOptions.interval = _vm.convert.minutesTo(value, 'seconds');
            _vm.validateRetryTimespans();
          }}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }