import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto",attrs:{"outlined":""}},[_c(VAppBar,[_c('h2',[_vm._v("Connections")]),_c(VSpacer),_c(VCol,{attrs:{"md":"3"}},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","data-cy":"search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c(VDataTable,{attrs:{"dense":"","headers":_vm.headers,"items":_vm.connections,"items-per-page":15,"search":_vm.search,"data-cy":"connections-table"},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" "),_c('copy',{attrs:{"content":item.id}})]}},{key:"item.dataSourceVersion",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"data-datasource-version":item.dataSourceVersion}},[_vm._v(_vm._s(_vm._f("date")(item.dataSourceVersion)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('tooltip',{attrs:{"message":item.canEdit ? 'Edit' : 'View'}},[_c('friss-icon',{staticClass:"mr-3",attrs:{"data-cy":"edit","name":"edit"},on:{"click":function($event){return _vm.editConnection(item.id)}}})],1),(_vm.$permissions.canModifyConnection)?_c('confirm',{attrs:{"title":"Delete connection","message":"Are you sure you want to to delete the connection?","yesText":"Delete","noText":"Cancel","disabled":!item.canEdit},on:{"confirmed":function($event){return _vm.deleteItem(item.id)}}},[_c('tooltip',{attrs:{"message":"Delete"}},[_c('friss-icon',{attrs:{"data-cy":"delete","name":"delete","disabled":!item.canEdit}})],1)],1):_vm._e()]}}],null,true)}),(_vm.$permissions.canModifyConnection)?_c('div',{staticStyle:{"display":"flex","padding":"20px","justify-content":"end"}},[_c(VBtn,{attrs:{"color":"secondary","rounded":"","outlined":"","data-cy":"add"},on:{"click":function($event){return _vm.addConnection()}}},[_vm._v(" Add connection ")])],1):_vm._e(),(_vm.showConnectionDialog)?_c('connection',{attrs:{"connectionId":_vm.selectedConnectionId,"dataproviderId":_vm.dataproviderId,"datasourceId":_vm.datasourceId},on:{"saved":_vm.getAll,"cancel":function($event){_vm.showConnectionDialog = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }