import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardText,[_c('h4',[_vm._v("From")]),_c(VSimpleTable,{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('td',{attrs:{"width":"10%"}},[_vm._v("Aggregator")]),_c('td',{attrs:{"width":"20%"}},[_vm._v("Path")]),_c('td',{attrs:{"width":"70%"}},[_vm._v("Where")]),_c('td',[(_vm.canAddFrom())?_c('tooltip',{attrs:{"message":"Add"}},[_c(VIcon,{attrs:{"data-cy":"fromadd"},on:{"click":function($event){return _vm.add()}}},[_vm._v("mdi-plus")])],1):_vm._e()],1)])]),_c('tbody',_vm._l((_vm.froms),function(item,i){return _c('tr',{key:i},[_c('td',[_c(VSelect,{attrs:{"items":_vm.$lookupService.selectMethods},model:{value:(item.selectMethod),callback:function ($$v) {_vm.$set(item, "selectMethod", $$v)},expression:"item.selectMethod"}})],1),_c('td',[(!_vm.schemaModel || _vm.schemaModel.schema.length === 0)?_c(VTextField,{attrs:{"data-cy":"frompath","rules":_vm.rules.fromPath},model:{value:(item.path),callback:function ($$v) {_vm.$set(item, "path", $$v)},expression:"item.path"}}):_c(VCombobox,{attrs:{"items":_vm.getSchemaFromMapped(item),"data-cy":"frompath","rules":_vm.rules.fromPath,"clearable":""},on:{"change":function (e) { return _vm.selectionChange(e, i); }},model:{value:(item.path),callback:function ($$v) {_vm.$set(item, "path", $$v)},expression:"item.path"}})],1),_c('td',[_c('whereComp',{attrs:{"wheres":item.wheres,"schema":_vm.getSchemaWhere(item)}})],1),_c('td',[_c('tooltip',{attrs:{"message":"Delete"}},[(_vm.$permissions.canModifyDataSource)?_c('friss-icon',{attrs:{"data-cy":"paramdelete","name":"delete"},on:{"click":function($event){return _vm.remove(i)}}}):_vm._e()],1)],1)])}),0)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }