import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticStyle:{"margin":"5px 0"}},[_c(VCardText,[_c(VSimpleTable,{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('td',{attrs:{"width":"30%"}},[_vm._v("DateFormat")]),_c('td',{attrs:{"width":"25%"}},[_vm._v("Type")]),_c('td',{attrs:{"width":"10%"}},[_vm._v("Operator")]),_c('td',{attrs:{"width":"30%"}},[_vm._v("Value")]),_c('td',{attrs:{"width":"5%"}},[_c(VIcon,{attrs:{"disabled":_vm.wheres.length > 0,"data-cy":"isvalidadd"},on:{"click":function($event){return _vm.add()}}},[_vm._v("mdi-plus")])],1)])]),_c('tbody',_vm._l((_vm.wheres),function(item,i){return _c('tr',{key:i},[_c('td',[_c('tooltip',{attrs:{"message":"This is used to parse the datetime values from the vendor response. The most used formats are yyyy-MM-dd, dd-MM-yyyy, MM-dd-yyyy"}},[(_vm.isDatetimeField(item.type))?_c(VTextField,{attrs:{"data-cy":"isvaliddateformat"},model:{value:(item.dateFormat),callback:function ($$v) {_vm.$set(item, "dateFormat", $$v)},expression:"item.dateFormat"}}):_vm._e()],1)],1),_c('td',[_c(VSelect,{attrs:{"items":_vm.$lookupService.types,"data-cy":"isvalidtype"},model:{value:(item.type),callback:function ($$v) {_vm.$set(item, "type", $$v)},expression:"item.type"}})],1),_c('td',[_c(VSelect,{attrs:{"items":_vm.$lookupService.getOperators(item.type),"data-cy":"isvalidoperator"},model:{value:(item.operator),callback:function ($$v) {_vm.$set(item, "operator", $$v)},expression:"item.operator"}})],1),_c('td',[_c(VTextField,{attrs:{"data-cy":"isvalidvalue"},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1),_c('td',[_c('tooltip',{attrs:{"message":"Delete"}},[(_vm.$permissions.canModifyDataSource)?_c('friss-icon',{attrs:{"data-cy":"paramdelete","name":"delete"},on:{"click":function($event){return _vm.remove(i)}}}):_vm._e()],1)],1)])}),0)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }