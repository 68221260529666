import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":600},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._t("default")],2)]}}],null,true),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{staticClass:"mx-auto",attrs:{"outlined":""}},[_c(VCardTitle,[_c('h2',[_vm._v("Copy expression")])]),_c(VCardText,[_c(VForm,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('p',[_vm._v("Copy '"+_vm._s(_vm.expression.name)+"' to:")]),_c(VForm,{ref:"form"},[_c(VSelect,{attrs:{"items":_vm.dataSources,"label":"Select","item-text":"name","item-value":"id","multiple":"","hint":"Select the data sources where you want the expression to be copied over","persistent-hint":"","rules":_vm.rules,"data-cy":"destination"},model:{value:(_vm.targetDataSourceIds),callback:function ($$v) {_vm.targetDataSourceIds=$$v},expression:"targetDataSourceIds"}})],1)],1)],1)],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"data-cy":"cancel","color":"secondary","rounded":"","outlined":""},on:{"click":_vm.cancel}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"data-cy":"copy-to","color":"primary","rounded":""},on:{"click":_vm.copyTo}},[_vm._v("Copy over")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }