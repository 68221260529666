import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":_vm.options.width},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._t("default")],2)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(_vm._s(_vm.options.header))]),_c(VCardText,[_vm._v(_vm._s(_vm.message))]),_c(VCardActions,[_c(VSpacer),(_vm.options.yesNo)?_c(VBtn,{attrs:{"data-cy":"no","color":"secondary","outlined":""},on:{"click":function($event){return _vm.no()}}},[_vm._v(_vm._s(_vm.options.noText || "No"))]):_vm._e(),(_vm.options.yesNo)?_c(VBtn,{attrs:{"data-cy":"yes","color":"primary"},on:{"click":function($event){return _vm.ok()}}},[_vm._v(_vm._s(_vm.options.yesText || "Yes"))]):_vm._e(),(!_vm.options.yesNo)?_c(VBtn,{attrs:{"data-cy":"ok","color":"primary"},on:{"click":function($event){return _vm.ok()}}},[_vm._v("OK")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }