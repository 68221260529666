import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto",staticStyle:{"margin=top":"12px"},attrs:{"outlined":""}},[_c(VAppBar,[_c('img',{staticStyle:{"margin-right":"12px"},attrs:{"src":"/img/friss-logo.svg","width":"70px"}}),_vm._v(" Data Service ")]),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[(_vm.model.configuration.dataServiceSettings)?_c(VAutocomplete,{attrs:{"items":_vm.dataSources,"label":"Data Source","data-cy":"datasource","item-text":"name","item-value":"name"},on:{"change":_vm.dataServiceSelectionChange},model:{value:(_vm.model.configuration.dataServiceSettings.name),callback:function ($$v) {_vm.$set(_vm.model.configuration.dataServiceSettings, "name", $$v)},expression:"model.configuration.dataServiceSettings.name"}}):_vm._e()],1)],1),_c(VRow,[_c(VCol,[(_vm.itemsFromSchema && _vm.itemsFromSchema.length > 0)?_c(VDataTable,{attrs:{"dense":"","data-cy":"fields-table","headers":_vm.headers,"items":_vm.itemsFromSchema,"items-per-page":5,"search":_vm.search,"item-key":"path","show-select":""},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
var item = ref.item;
return [_c(VSimpleCheckbox,{attrs:{"data-cy":'select-' + item.path,"value":isSelected},on:{"input":function($event){return select($event)}}})]}},{key:"top",fn:function(){return [_c(VTextField,{attrs:{"label":"Search","data-cy":"search-fields","clearable":"","clear-icon":"mdi-close-circle-outline"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.path",fn:function(ref){
var item = ref.item;
return [_c(VTextField,{attrs:{"readonly":"","data-cy":'path-' + item.path},model:{value:(item.path),callback:function ($$v) {_vm.$set(item, "path", $$v)},expression:"item.path"}})]}},{key:"item.query",fn:function(ref){
var item = ref.item;
return [_c(VTextField,{attrs:{"data-cy":'query-' + item.path,"disabled":!_vm.selected(item.path),"rules":_vm.selected(item.path) ? _vm.rules.required : []},on:{"change":function($event){return _vm.onItemChanged(item)}},model:{value:(item.query),callback:function ($$v) {_vm.$set(item, "query", $$v)},expression:"item.query"}})]}},{key:"item.fuzzyMatch",fn:function(ref){
var item = ref.item;
return [_c(VCheckbox,{attrs:{"data-cy":'fuzzy-match-' + item.path},on:{"change":function($event){return _vm.onItemChanged(item)}},model:{value:(item.fuzzyMatch),callback:function ($$v) {_vm.$set(item, "fuzzyMatch", $$v)},expression:"item.fuzzyMatch"}})]}},{key:"item.threshold",fn:function(ref){
var item = ref.item;
return [_c(VTextField,{attrs:{"disabled":!item.fuzzyMatch,"data-cy":'threshold-' + item.path,"type":"number","rules":item.fuzzyMatch ? _vm.rules.threshold : []},on:{"change":function($event){return _vm.onItemChanged(item)}},model:{value:(item.threshold),callback:function ($$v) {_vm.$set(item, "threshold", $$v)},expression:"item.threshold"}})]}},{key:"item.matchType",fn:function(ref){
var item = ref.item;
return [_c(VSelect,{attrs:{"items":_vm.$lookupService.matchingTypes,"disabled":!item.fuzzyMatch,"data-cy":'match-type-' + item.path},on:{"change":function($event){return _vm.onItemChanged(item)}},model:{value:(item.matchType),callback:function ($$v) {_vm.$set(item, "matchType", $$v)},expression:"item.matchType"}})]}}],null,true),model:{value:(_vm.model.configuration.dataServiceSettings.searchFields),callback:function ($$v) {_vm.$set(_vm.model.configuration.dataServiceSettings, "searchFields", $$v)},expression:"model.configuration.dataServiceSettings.searchFields"}}):_vm._e(),(_vm.errorMessage)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),_c('div',{staticStyle:{"display":"flex","justify-content":"end"}},[_c('confirm',{attrs:{"disabled":_vm.applyDisabled,"title":"Apply data service","yesText":"Apply","noText":"Cancel","color":"secondary","message":"\n            Applying data service request will override current request. Are you sure you want to apply the data service request?\n          "},on:{"confirmed":function($event){return _vm.applyDataServiceRequest()}}},[_c(VBtn,{attrs:{"disabled":_vm.applyDisabled,"rounded":"","color":"secondary","outlined":"","data-cy":"apply"}},[_vm._v("Apply")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }