import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto"},[_c(VAppBar,[_c('h2',{attrs:{"data-cy":"page-title"}},[_vm._v("Data providers")]),_c(VSpacer),_c(VCol,{attrs:{"md":"3"}},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","data-cy":"search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.dataProviders,"items-per-page":15,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"search":_vm.search,"dense":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.name.toLowerCase() === _vm.constants.friss)?_c('div',{staticClass:"hover-pointer",staticStyle:{"display":"flex","justify-content":"flex-start","align-items":"center"},on:{"click":function($event){return _vm.dataSources(item.id, item.name)}}},[_c(VIcon,{staticClass:"mr-3",attrs:{"small":""}},[_vm._v("$frissIcon")]),_vm._v(" DataService ")],1):_c('span',{staticClass:"hover-pointer",on:{"click":function($event){return _vm.dataSources(item.id, item.name)}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.links",fn:function(ref){
var item = ref.item;
return _vm._l((item.links),function(link,i){return _c('span',{key:i},[_c('a',{attrs:{"href":link.uri,"target":"_blank"}},[_vm._v(_vm._s(link.label))]),_vm._v(" "),_c('br')])})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('tooltip',{attrs:{"message":"Edit"}},[_c('friss-icon',{staticClass:"mr-3",attrs:{"data-cy":"edit","disabled":_vm.isDataService(item),"name":"edit"},on:{"click":function($event){_vm.isDataService(item) ? null : _vm.edit(item.id)}}})],1),_c('tooltip',{attrs:{"message":"Data sources"}},[_c('friss-icon',{staticClass:"mr-3",attrs:{"data-cy":"datasource","name":"globe"},on:{"click":function($event){return _vm.dataSources(item.id, item.name)}}})],1),(_vm.$permissions.canModifyDataSource)?_c('confirm',{attrs:{"title":"Delete data provider","message":'Are you sure you want to to delete ' + item.name + '?',"yesText":"Delete","noText":"Cancel","disabled":_vm.isDataService(item)},on:{"confirmed":function($event){return _vm.deleteItem(item.id)}}},[_c('tooltip',{attrs:{"message":"Delete"}},[_c('friss-icon',{attrs:{"data-cy":"delete","small":"","name":"delete","disabled":_vm.isDataService(item)}})],1)],1):_vm._e()]}}],null,true)}),_c('div',{staticStyle:{"display":"flex","padding":"20px","justify-content":"end"}},[_c('input',{ref:"importFileElement",staticStyle:{"display":"none"},attrs:{"type":"file","accept":".json"},on:{"change":_vm.onImportFileChanged}}),_c(VBtn,{staticClass:"mx-2",attrs:{"data-cy":"import","color":"secondary","outlined":"","rounded":""},on:{"click":function () { return _vm.$refs.importFileElement.click(); }}},[_vm._v(" Import data sources ")]),(_vm.$permissions.canModifyDataSource)?_c(VBtn,{staticClass:"mx-2",attrs:{"data-cy":"add","color":"secondary","outlined":"","rounded":""},on:{"click":function($event){return _vm.create()}}},[_vm._v(" Add data provider ")]):_vm._e()],1),(_vm.dataProviderId !== null)?_c('dataprovider',{attrs:{"dataProviderId":_vm.dataProviderId},on:{"saved":_vm.getAll,"close":function($event){_vm.dataProviderId = null}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }