import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
return [_c('span',_vm._b({on:{"click":function($event){$event.stopPropagation();return _vm.openDialog()}}},'span',attrs,false),[_vm._t("default")],2)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(_vm._s(_vm.title))]),_c(VCardText,[_vm._v(_vm._s(_vm.message))]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"data-cy":"no","color":"secondary","rounded":"","outlined":""},on:{"click":function($event){return _vm.no()}}},[_vm._v(_vm._s(_vm.noMessage))]),_c(VBtn,{attrs:{"data-cy":"yes","color":"primary","rounded":""},on:{"click":function($event){return _vm.yes()}}},[_vm._v(_vm._s(_vm.yesMessage))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }