import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto"},[_c(VAppBar,[_c('h2',[_vm._v("Data Sources")]),_c(VSpacer),_c(VCol,{attrs:{"md":"3"}},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","data-cy":"search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.dataSources,"items-per-page":15,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"show-expand":"","search":_vm.search,"dense":"","show-select":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"35px"},attrs:{"colspan":headers.length}},[_c('versionedDataSources',{ref:"versionedDataSource",attrs:{"dataproviderId":_vm.dataproviderId,"dataSourceId":item.id},on:{"onDeleted":function($event){return _vm.getAll()}}})],1)]}},{key:"item.data-table-expand",fn:function(ref){
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(!isExpanded)?_c('friss-icon',{attrs:{"data-cy":"expand_versions","tabindex":"0","title":"Show versions","name":"chevron-down-alt-lg"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return expand(true)},"click":function($event){return expand(true)}}}):_vm._e(),(isExpanded)?_c('friss-icon',{attrs:{"data-cy":"collapse_versions","tabindex":"0","title":"Hide versions","name":"chevron-up-alt-lg"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return expand(false)},"click":function($event){return expand(false)}}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('tooltip',{attrs:{"message":"Edit"}},[_c('friss-icon',{staticClass:"mr-3",attrs:{"data-cy":"edit","color":"secondary","name":"edit"},on:{"click":function($event){return _vm.editDataSource(item.id)}}})],1),_c('tooltip',{attrs:{"message":"Connections"}},[_c('friss-icon',{staticClass:"mr-3",attrs:{"data-cy":"connections","name":"network-view"},on:{"click":function($event){return _vm.connections(item.id, item.name, item.correlationId)}}})],1),_c('tooltip',{attrs:{"message":"Expressions"}},[_c('friss-icon',{staticClass:"mr-3",attrs:{"data-cy":"expressions","disabled":_vm.isbase64ResponseType(item.responseType),"name":"filter"},on:{"click":function($event){return _vm.expressions(item.id, item.name)}}})],1)]}}],null,true),model:{value:(_vm.selectedDataSources),callback:function ($$v) {_vm.selectedDataSources=$$v},expression:"selectedDataSources"}}),_c('div',{staticStyle:{"display":"flex","padding":"20px","justify-content":"end"}},[_c(VBtn,{staticClass:"mx-2",attrs:{"disabled":_vm.selectedDataSources.length === 0,"color":"secondary","rounded":"","outlined":"","data-cy":"export-selection"},on:{"click":function($event){return _vm.exportSelectedDataSources()}}},[_vm._v(" Export selection ")]),_c('tooltip',{attrs:{"message":_vm.$permissions.canModifyDataSource ? 'Create new data source' : 'Direct edit is disabled. Please make your changes in a lower environment (like SPA), export the datasource file and import it here.'}},[_c(VBtn,{staticClass:"mx-2",attrs:{"disabled":!_vm.$permissions.canModifyDataSource,"color":"secondary","rounded":"","outlined":"","data-cy":"add"},on:{"click":function($event){return _vm.createDataSource()}}},[_vm._v(" Add data source ")])],1)],1),(_vm.selectedDataSourceId !== null)?_c('dataSource',{attrs:{"datasourceId":_vm.selectedDataSourceId,"dataproviderId":_vm.dataproviderId,"isDraft":true},on:{"saved":function($event){return _vm.refreshTables()},"close":function($event){_vm.selectedDataSourceId = null}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }