import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDataTable,{attrs:{"dense":"","data-cy":"versioned","headers":_vm.headers,"items":_vm.expressions,"items-per-page":10},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" "),_c('copy',{attrs:{"content":item.id}})]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"data-created-date":item.createdDate}},[_vm._v(_vm._s(_vm._f("date")(item.createdDate)))])]}},{key:"item.importedDate",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"data-imported-date":item.importedDate}},[_vm._v(_vm._s(_vm._f("date")(item.importedDate)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('tooltip',{attrs:{"message":"View"}},[_c('friss-icon',{staticClass:"mr-3",attrs:{"data-cy":"view","name":"information"},on:{"click":function($event){_vm.selectedExpressionId = item.id}}})],1),_c('tooltip',{attrs:{"message":"Delete"}},[(_vm.$permissions.canModifyDataSource)?_c('confirm',{attrs:{"title":"Delete version","message":"Are you sure to delete the published expression? It might be used in some KBs","yesText":"Delete","noText":"Cancel"},on:{"confirmed":function($event){return _vm.deleteItem(item.id)}}},[_c('friss-icon',{attrs:{"data-cy":"deletepbl","name":"delete"}})],1):_vm._e()],1)]}}],null,true)}),(_vm.selectedExpressionId !== null)?_c('expression',{attrs:{"expressionId":_vm.selectedExpressionId,"isDraft":false},on:{"close":function($event){_vm.selectedExpressionId = null}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }