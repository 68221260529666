import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto",attrs:{"outlined":""}},[_c(VAppBar,[_c('h4',[_vm._v("Links")]),_c(VSpacer),_c('tooltip',{attrs:{"message":"Add link"}},[_c(VBtn,{staticClass:"mx-2",attrs:{"small":"","fab":""},on:{"click":function($event){return _vm.add()}}},[_c(VIcon,{attrs:{"data-cy":"add"}},[_vm._v("mdi-plus")])],1)],1)],1),_c(VSimpleTable,[_c('thead',[_c('tr',[_c('td',[_vm._v("Label")]),_c('td',[_vm._v("Url")])])]),_c('tbody',_vm._l((_vm.links),function(item,i){return _c('tr',{key:i},[_c('td',[_c(VTextField,{attrs:{"rules":_vm.rules.required,"data-cy":"link-label"},model:{value:(item.label),callback:function ($$v) {_vm.$set(item, "label", $$v)},expression:"item.label"}})],1),_c('td',[_c(VTextField,{attrs:{"rules":_vm.rules.url,"data-cy":"link-uri"},model:{value:(item.uri),callback:function ($$v) {_vm.$set(item, "uri", $$v)},expression:"item.uri"}})],1),_c('td',[_c('tooltip',{attrs:{"message":"Delete"}},[_c('friss-icon',{attrs:{"data-cy":"paramdelete","name":"delete"},on:{"click":function($event){return _vm.remove(i)}}})],1)],1)])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }