import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDataTable,{attrs:{"dense":"","data-cy":"versioned","headers":_vm.headers,"items":_vm.dataSources,"items-per-page":10},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('tooltip',{attrs:{"message":"View"}},[_c('friss-icon',{staticClass:"mr-3",attrs:{"data-cy":"view","name":"information"},on:{"click":function($event){_vm.selectedDataSourceId = item.id}}})],1),_c('tooltip',{attrs:{"message":"Delete"}},[(_vm.$permissions.canModifyDataSource)?_c('confirm',{attrs:{"title":_vm.isLastRemaining ? 'Delete data source' : 'Delete version',"message":_vm.isLastRemaining
              ? 'Are you sure to delete the data source? This will delete the definition and all associated expression. Do you want to continue?'
              : 'Are you sure to delete this data source version?',"yesText":"Delete","noText":"Cancel"},on:{"confirmed":function($event){return _vm.deleteItem(item.id)}}},[_c('friss-icon',{attrs:{"data-cy":"deletepbl","name":"delete"}})],1):_vm._e()],1)]}},{key:"item.createdDate",fn:function(ref){
              var item = ref.item;
return [_c('span',{attrs:{"data-created-date":item.createdDate}},[_vm._v(_vm._s(_vm._f("date")(item.createdDate)))])]}},{key:"item.importedDate",fn:function(ref){
              var item = ref.item;
return [_c('span',{attrs:{"data-imported-date":item.importedDate}},[_vm._v(_vm._s(_vm._f("date")(item.importedDate)))])]}}],null,true)}),(_vm.selectedDataSourceId !== null)?_c('dataSource',{attrs:{"datasourceId":_vm.selectedDataSourceId,"isDraft":false},on:{"saved":_vm.getAll,"close":function($event){_vm.selectedDataSourceId = null}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }