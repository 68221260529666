import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":1600},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._t("default")],2)]}}],null,true),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{staticClass:"mx-auto",attrs:{"outlined":""}},[_c(VAppBar,[_c('h2',[_vm._v("Expression")])]),_c(VCardText,[_c(VForm,{ref:"form"},[_c(VRow,[_c(VCol,{attrs:{"md":"3"}},[_c(VTextField,{attrs:{"data-cy":"name","label":"Expression Name","rules":_vm.rules.name},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})],1),_c(VCol,{attrs:{"md":"3"}},[_c(VTextField,{attrs:{"data-cy":"orderBy","label":"Order BY"},model:{value:(_vm.model.query.orderBy.elementName),callback:function ($$v) {_vm.$set(_vm.model.query.orderBy, "elementName", $$v)},expression:"model.query.orderBy.elementName"}})],1),_c(VCol,{attrs:{"md":"3"}},[_c(VCheckbox,{attrs:{"data-cy":"descending","label":"Descending"},model:{value:(_vm.model.query.orderBy.descending),callback:function ($$v) {_vm.$set(_vm.model.query.orderBy, "descending", $$v)},expression:"model.query.orderBy.descending"}})],1),_c(VCol,{attrs:{"md":"12"}},[_c('fromComp',{attrs:{"froms":_vm.model.query.froms,"schemaModel":_vm.schemaModel},on:{"selected":_vm.parentSchemaChanged}})],1),_c(VCol,{attrs:{"md":"12"}},[_c('aggregationComp',{attrs:{"aggregations":_vm.model.query.aggregations,"schema":_vm.selectedSchema}})],1),_c(VCol,{attrs:{"md":"12"}},[_c('selectComp',{attrs:{"select":_vm.model.query.select,"schema":_vm.selectedSchema}})],1)],1)],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"data-cy":"cancel","color":"secondary","rounded":"","outlined":""},on:{"click":_vm.cancel}},[_vm._v("Cancel")]),(_vm.$permissions.canModifyDataSource && _vm.isDraft)?_c(VBtn,{attrs:{"data-cy":"save","color":"primary","rounded":""},on:{"click":_vm.save}},[_vm._v("Save")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }