import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"open-on-hover":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticStyle:{"margin-bottom":"10px"},attrs:{"icon":"","data-cy":_vm.dataCy,"color":_vm.isActive(_vm.path) ? 'primary' : ''},on:{"click":function($event){return _vm.$emit('icon-clicked')}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.icon))])],1)]}}])},[_c(VList,{staticStyle:{"padding":"0"},attrs:{"dense":""}},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }